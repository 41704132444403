import React from 'react';
import defaultGimbalLogo from '../assets/images/gimbal-icon-on-bright.png';
import { textMap } from '../utils/text-mapper';
import { STATUS } from '../utils/constants';

const Page = ({ error='', pickupStatus='', configuration={}, handleClick }) => {
  if (![STATUS.ON_THE_WAY, STATUS.I_AM_HERE, STATUS.ORDER_FULFILLED].includes(pickupStatus)) {
    pickupStatus = STATUS.NOT_AVAILABLE
  }

  const { color='black', logo=defaultGimbalLogo, name='Gimbal' } = configuration;

  const { buttonInstruction, buttonText, instruction, status } = textMap(name)[pickupStatus];

  return (
    <div className='main-container'>
      <div className='brand-logo'>
        <img src={logo} alt='brand-logo-160' />
      </div>

      <h4>Your current status</h4>

      <h1>{status}</h1>

      <h3>{instruction}</h3>
      <br />
      <h4>{buttonInstruction}</h4>

      <button
        className='status-btn'
        style={{ backgroundColor: color }}
        onClick={handleClick}
      >
        {buttonText}
      </button>

      {
        error
          ? <p><br/><samp><br/>{ error }</samp></p>
          : null
      }
    </div>
  );
};

export default Page;
