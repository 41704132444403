import React from 'react';
import { isMobile } from "react-device-detect";
import { DashboardScreen } from './dashboard-screen';
import { QrcodeScreen } from './qrcode-screen';

const MainScreen = () => {
  return isMobile ? <DashboardScreen /> : <QrcodeScreen />
};

export { MainScreen };
