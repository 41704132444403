import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { MainScreen } from './screens/main-screen';
import { RedirectScreen } from './screens/redirect-screen';
import './styles/App.css';

const App = () => {
  return (
    <Switch>
      <Route path='/(otw|ih|of)/:uuid' component={ MainScreen } />
      <Route path='*' component={ RedirectScreen } />
    </Switch>
  );
};

export default App;
