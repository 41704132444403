import { STATUS } from './constants';

const textMap = brandName => ({
  [STATUS.ON_THE_WAY]: {
    status: 'On My Way',
    instruction: 'Please keep this page open for fastest pickup times.',
    buttonInstruction: 'Tap when you arrive on site.',
    buttonText: "I'm Here",
  },
  [STATUS.I_AM_HERE]: {
    status: "I'm Here",
    instruction: 'Please keep this page open so staff can find you more easily.',
    buttonInstruction: 'Tap when your order has been fulfilled.',
    buttonText: 'Pickup Complete',
  },
  [STATUS.ORDER_FULFILLED]: {
    status: 'Order Fulfilled',
    instruction: `Thanks for using ${brandName}'s curbside pickup solution. You can now close this window.`,
    buttonInstruction: 'Did we nail it?',
    buttonText: 'Take a Survey',
  },
  [STATUS.NOT_AVAILABLE]: {
    status: 'Not Available',
    instruction: 'Please try again later.',
    buttonInstruction: '',
    buttonText: 'Reload',
  }
});

export { textMap };
