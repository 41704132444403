const ERROR = {
  GET_POSITION: 'Cannot locate the current position',
  PERMISSION: 'Permission Denied',
  PLATFORM: 'This platform does not support geolocation',
  SERVER: 'Server Error',
  WATCH_POSITION: 'Error watch position',
};

const STATUS = {
  I_AM_HERE: 'ARRIVED',
  NOT_AVAILABLE: 'NA',
  ON_THE_WAY: 'OPENED',
  ORDER_FULFILLED: 'CLOSED',
};

const URL = {
  MANAGER_WEB_API: `${process.env.REACT_APP_MANAGER_WEB_API}`,
  ORDER_API: `${process.env.REACT_APP_ORDER_API}`,
  GIMBAL: 'https://www.gimbal.com',
};

export {
  ERROR,
  STATUS,
  URL,
};
