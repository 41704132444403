import {
  ERROR,
  URL
} from '../utils/constants';

const getConfiguration = async (subdomain) => {
  try {
    const response = await fetch(`${URL.MANAGER_WEB_API}/${subdomain}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const data = await response.json();

      return(data);
    }

    throw new Error(response.status || ERROR.SERVER);
  } catch (error) {
    console.log("getConfiguration Error: ", error);

    return({ error: error });
  }
};

const postPickup = async (status='', uuid='') => {
  try {
    const response = await fetch(`${URL.ORDER_API}/${uuid}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        status: status,
      }),
    });

    if (response.ok) {
      const data = await response.json();

      return(data);
    }

    throw new Error(response.status || ERROR.SERVER);
  } catch (error) {
    console.log("postPickup Error: ", error);

    return({ error: error });
  }
};

export {
  getConfiguration,
  postPickup,
};
