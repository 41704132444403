/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { getConfiguration } from '../api/otw-client';
import Loader from '../components/loader';
import { URL } from '../utils/constants';

const RedirectScreen = () => {
  useEffect(() => {
    fetchConfigAndRedirect();
  }, []);

  const fetchConfigAndRedirect = async () => {
    const subdomain = window.location.host.split('.gimbal.com')[0];

    const response = await getConfiguration(subdomain);

    response.redirectUrl
      ? redirect(response.redirectUrl)
      : redirect(URL.GIMBAL);
  };

  const redirect = (url) => {
    window.location.href = url;

    return null;
  };

  return (
    <Loader />
  );
};

export { RedirectScreen };
