/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import { clearTrack, track } from '../utils/navigator';
import { getConfiguration, postPickup } from '../api/otw-client';
import Loader from '../components/loader';
import Page from '../components/page';
import { STATUS, URL } from '../utils/constants';
import '../styles/dashboard-screen.css';

const DashboardScreen = () => {
  const { uuid } = useParams();

  const [configuration, setConfiguration] = useState({});
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [pickup, setPickup] = useState({});
  const [watchId, setWatchId] = useState(-1);

  useEffect(() => {
    const promiseConfiguration = fetchConfiguration();
    const promisePickup = fetchPickup(STATUS.ON_THE_WAY);

    Promise.allSettled([promiseConfiguration, promisePickup])
      .then(() => {
        if (isLoading) {
          setIsLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    pickup.status === STATUS.ON_THE_WAY
      ? monitorLocation()
      : resetMonitorLocation();
  }, [pickup]);

  const fetchConfiguration = async () => {
    const subdomain = window.location.host.split('.gimbal.com')[0];

    const response = await getConfiguration(subdomain);

    response.error
      ? setConfiguration({})
      : setConfiguration(response);
  };

  const fetchPickup = async (status) => {
    const response = await postPickup(status, uuid);

    if (response.error) {
      setPickup({});
      setError(`${response.error}`);
    } else {
      const pickupObject = mapPickupObjectByGeofenceType(response);

      setPickup(pickupObject);
    }
  };

  const mapPickupObjectByGeofenceType = data => {
    return (
      data.hasOwnProperty('polygonGeofenceAsList')
        ? {
            status: data.pickup.status,
            geofence: {
              coordinates: data.polygonGeofenceAsList,
              type: 'polygon'
            }
          }
        : {
          status: data.pickup.status,
          geofence: {
            coordinates: data.circleGeofenceCenter,
            radius: data.circleGeofenceRadius,
            type: 'radial'
          }
        }
    );
  };

  const handleFetchPickup = async (status) => {
    setIsLoading(true);
    await fetchPickup(status);
    setIsLoading(false);
  };

  const monitorLocation = () => {
    if (watchId < 0 && pickup.geofence) {
      const id = track(pickup.geofence, monitorCallback);

      setWatchId(id);
    }
  };

  const monitorCallback = (error, {hasArrived}) => {
    console.log(error);
    if (hasArrived) {
      handleFetchPickup(STATUS.I_AM_HERE);
    }
  }

  const resetMonitorLocation = () => {
    if (watchId >= 0) {
      clearTrack(watchId);
      setWatchId(-1);
    }
  };

  const handleClick = () => {
    switch (pickup.status) {
      case STATUS.ON_THE_WAY:
        handleFetchPickup(STATUS.I_AM_HERE);
        break;
      case STATUS.I_AM_HERE:
        handleFetchPickup(STATUS.ORDER_FULFILLED);
        break;
      case STATUS.ORDER_FULFILLED:
        window.location.href = configuration.redirectUrl || URL.GIMBAL;
        break;
      default:
        resetMonitorLocation();
        window.location.reload();
    }
  }

  return (
    isLoading
      ? <Loader />
      : <Page error={error} pickupStatus={pickup.status} configuration={configuration} handleClick={handleClick} />
  );
};

export { DashboardScreen };
