import pointInPolygon from 'point-in-polygon';
import haversine from 'haversine';
import { ERROR } from '../utils/constants';

const isInPolygon = (position, polygon) => pointInPolygon(position, polygon);
const isInRadial = (currentPosition, destination, radius) => haversine(currentPosition, destination, { threshold: radius, unit: 'meter' });

const track = (geofence, callback) => {
  if (typeof navigator === 'undefined' || !navigator.geolocation) {
    return callback({ message: ERROR.PLATFORM }, {});
  }

  return navigator.geolocation.watchPosition(
    (position) => {
      if (!position || !position.coords) {
        return callback({ message: ERROR.GET_POSITION }, {});
      }

      /*
      `position.coors` returns:
      GeolocationCoordinates { latitude: 11, longitude: 222, altitude: 333, accuracy: 44, altitudeAccuracy: 10, heading: null, speed: null }
      */

      const { latitude, longitude } = position.coords;
      const { coordinates, radius, type } = geofence;

      if (type === 'polygon') {
        if (isInPolygon([latitude, longitude], coordinates)) {
          return callback(null, { hasArrived: true });
        } else {
          return callback(null, { hasArrived: false });
        }
      } else {
        if (isInRadial({latitude, longitude}, {latitude: coordinates[0], longitude: coordinates[1]}, radius)) {
          return callback(null, { hasArrived: true });
        } else {
          return callback(null, { hasArrived: false });
        }
      }
    },
    (err) => {
      if (err && err.code && err.code === 1) {
        return callback({ message: ERROR.PERMISSION }, {});
      }

      return callback({ message: ERROR.WATCH_POSITION }, {});
    },
    {
      enableHighAccuracy: true,
      distanceFilter: 1,
      // maximumAge: 15000,
      // timeout: 20000,
    }
  );
};

const clearTrack = (id) => {
  navigator.geolocation.clearWatch(id);
}

export {
  clearTrack,
  track,
}
