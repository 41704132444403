/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';

import { getConfiguration } from '../api/otw-client';
import Loader from '../components/loader';
import '../styles/qrcode-screen.css';

const QrcodeScreen = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [configuration, setConfiguration] = useState({});

  const fetchConfiguration = async () => {
    const subdomain = window.location.host.split('.gimbal.com')[0];

    const response = await getConfiguration(subdomain);

    response.error
      ? setConfiguration({})
      : setConfiguration(response);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchConfiguration();
  }, []);

  const renderContent = (
    <div>
      <div className='flex-row'>
        <div className='box box-l'>
          <h1 style={{ color: configuration.color }}>
            We do not offer<br />
            desktop browser support<br />
            for In-Store or Curbside Pickup.
          </h1>
          <br /><br /><br />
          <h3>
            Please open up your order confirmation on a mobile<br />
            device so we can offer you the best experience possible.
          </h3>
        </div>

        <div className='box box-r'>
          <div className="qr-section" style={{ borderColor: configuration.color }}>
            <QRCode
              value={window.location.href}
              size={220}
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              level={"L"}
              includeMargin={false}
              renderAs={"svg"}
            />
          </div>
          <br />
          <br />
          <h1 style={{ color: configuration.color }}>
            SCAN FOR MOBILE SITE
          </h1>
        </div>
      </div>
    </div>
  );


  return (
    isLoading
      ? <Loader />
      : renderContent
  );
};

export { QrcodeScreen };
